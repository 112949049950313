import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';

import { Profile } from '../shared/profile.type';
import { ProfileState, profileStatePath } from './profile.reducer';

export const selectProfileState =
  createFeatureSelector<ProfileState>(profileStatePath);

export const selectProfile = createSelector(
  selectProfileState,
  state => state.entity,
);

export const selectProfileId = createSelector(
  selectProfile,
  profile => profile && profile.id,
);

const profileHasRole =
  profile =>
  (role: string): boolean =>
    profile && profile.roles && profile.roles.includes(role);

export const selectHasRole = createSelector(
  selectProfile,
  (profile: Profile, role: string) => profileHasRole(profile)(role),
);

const profileHasPermission =
  profile =>
  (permission: string): boolean =>
    profile && profile.permissions && profile.permissions.includes(permission);

export const selectHasPermission = createSelector(
  selectProfile,
  (profile: Profile, permission: string) =>
    profileHasPermission(profile)(permission),
);

export const selectOffice = createSelector(
  selectProfile,
  profile => profile.office,
);

export const selectPrimaryIdentity = createSelector(
  selectProfile,
  profile =>
    profile &&
    profile.identities &&
    (profile.identities.find(identity => identity.primary) ||
      profile.identities[0]),
);

export const selectPrimaryIdentityName = createSelector(
  selectPrimaryIdentity,
  identity => (identity && identity.name) || '',
);

export const selectProfileInfo = createSelector(
  selectProfile,
  selectPrimaryIdentity,
  (profile, primaryIdentity) => ({
    ...profile,
    primaryIdentity,
    hasRole: profileHasRole(profile),
  }),
);

/* istanbul ignore next */
@Injectable()
export class ProfileSelectors {
  constructor(private store: Store<ProfileState>) {}

  get office() {
    return this.store.pipe(select(selectOffice));
  }

  get profile() {
    return this.store.pipe(select(selectProfile));
  }

  get profileId() {
    return this.store.pipe(select(selectProfileId));
  }

  get primaryIdentity() {
    return this.store.pipe(select(selectPrimaryIdentity));
  }

  get primaryIdentityName() {
    return this.store.pipe(select(selectPrimaryIdentityName));
  }

  hasRole(role: string) {
    return this.store.pipe(select(selectHasRole, role));
  }

  hasPermission(permission: string) {
    return this.store.pipe(select(selectHasPermission, permission));
  }

  get profileInfo() {
    return this.store.pipe(select(selectProfileInfo));
  }
}
