// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { merge, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import {
  DetailedErrorService,
  ErrorComponentLocationMap,
  ErrorSource,
} from '@app/core';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { AppState } from '@app/core/store/store/app-store.reducer';
import { ProcedureInteraction } from '@app/features/procedure-interaction/shared/procedure-interaction.type';
import {
  completeProcedureInteraction,
  deleteProcedureInteraction,
  getProcedureInteraction,
  updateProcedureInteraction,
} from '@app/features/procedure-interaction/store/procedure-interaction.actions';
import { ProcedureInteractionSelectors } from '@app/features/procedure-interaction/store/procedure-interaction.selectors';
import { isNoValidServiceTicketError } from '@app/modules/orders/shared/order-utils';
import { ToastMessageService } from '@app/shared/components/toast';
import { cloneDeep, filterTruthy, isNotNullOrUndefined } from '@app/utils';

import { Commentable } from '../../../../modules/comments/shared/comments.type';
import { validateWithJsonSchema } from '../procedure-interaction-dynamic-form/dynamic-form.util';

@Component({
  selector: 'omg-procedure-interaction',
  templateUrl: './procedure-interaction.component.html',
  styleUrls: ['./procedure-interaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      // captures the validity of the form, but does not do any autosaving.
      // child components are responsible for dispatching actions.
      provide: UntypedFormGroup,
      useValue: new UntypedFormGroup({
        procedureType: new UntypedFormControl('', [Validators.required]),
        procedureSummary: new UntypedFormControl('', [requiredAfterTrim]),
        measurements: new UntypedFormArray([]),
        resultsInterpretation: new UntypedFormControl(),
        dynamicForm: new UntypedFormGroup({}),
      }),
    },
  ],
})
export class ProcedureInteractionComponent implements OnInit, OnDestroy {
  procedureInteraction?: ProcedureInteraction;

  loadingProcedureInteraction: Observable<boolean>;

  procedureInteractionCommentable?: Commentable;
  commentsVisible: boolean;

  featureDynamicFormsEnabled = false;

  actionBarErrors: string[] = [];

  private unsubscribe = new Subject<void>();

  errorKeyMap: ErrorComponentLocationMap = {
    actionBar: [ErrorSource.Base, 'signer'],
  };

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private changeRef: ChangeDetectorRef,
    private procedureInteractionSelectors: ProcedureInteractionSelectors,
    private toastService: ToastMessageService,
    private detailedErrorService: DetailedErrorService,
    private launchDarklyService: LaunchDarklyService,
    public formState: UntypedFormGroup, // injected in `@Component()#providers` above via Angular's dependency injection
  ) {}

  ngOnInit() {
    this.loadProcedureInteraction();

    this.launchDarklyService
      .variation$('billing-procedure-dynamic-forms', false)
      .pipe(take(1))
      .subscribe(
        featureEnabled => (this.featureDynamicFormsEnabled = featureEnabled),
      );

    this.mapErrorLocations();
  }

  loadProcedureInteraction() {
    this.route.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params =>
        this.store.dispatch(getProcedureInteraction({ id: params['id'] })),
      );

    this.procedureInteractionSelectors
      .get()
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(procedureInteraction => {
        this.procedureInteractionCommentable = {
          id: parseInt(procedureInteraction.id, 10),
          totalComments: procedureInteraction.totalComments,
          commentableType: 'procedure_interaction',
        };
        this.commentsVisible = procedureInteraction.totalComments > 0;
        this.procedureInteraction = cloneDeep(procedureInteraction);
        this.changeRef.markForCheck();
      });

    this.loadingProcedureInteraction =
      this.procedureInteractionSelectors.loading(getProcedureInteraction);
  }

  toggleComment() {
    this.commentsVisible = !this.commentsVisible;
  }

  updateCommentCount(action: 'add' | 'remove') {
    if (action === 'add') {
      this.procedureInteractionCommentable.totalComments += 1;
    } else {
      this.procedureInteractionCommentable.totalComments -= 1;
    }
  }

  onActionBarComplete() {
    validateWithJsonSchema(
      this.formState.get('dynamicForm') as UntypedFormGroup,
      this.procedureInteraction.dynamicForm?.stateSchema,
    );
    this.formState.markAllAsTouched();

    if (this.formState.valid) {
      this.store.dispatch(
        completeProcedureInteraction({
          id: this.procedureInteraction.id,
          allowClosedTicket: false,
        }),
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get showResults(): boolean {
    return this.measurementsRequired || this.resultDocumentRequired;
  }

  get showResultsInterpretation(): boolean {
    const resultsRequired =
      this.procedureInteraction?.procedureType?.isResultsInterpretationRequired;

    if (
      ['draft', 'complete'].includes(this.procedureInteraction.state) &&
      resultsRequired
    ) {
      return true;
    } else if (
      ['needs_review', 'reviewed'].includes(this.procedureInteraction.state) &&
      (this.resultDocumentRequired || resultsRequired)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private get resultDocumentRequired(): boolean {
    return this.procedureInteraction?.procedureType
      ?.isDocumentReferenceRequired;
  }

  private get measurementsRequired(): boolean {
    return (
      this.procedureInteraction?.procedureType?.measurementTypes.length > 0
    );
  }

  // map response errors to their display locations
  private mapErrorLocations() {
    merge(
      this.procedureInteractionSelectors.error(completeProcedureInteraction),
      this.procedureInteractionSelectors.error(deleteProcedureInteraction),
      this.procedureInteractionSelectors.error(updateProcedureInteraction),
    )
      .pipe(
        takeUntil(this.unsubscribe),
        filterTruthy(),
        filter((errors: any) => {
          return !errors.some((err: any) => isNoValidServiceTicketError(err));
        }),
      )
      .subscribe((errors: any[]) => {
        const mappedErrors =
          this.detailedErrorService.getMappedErrorsByLocation(
            errors,
            this.errorKeyMap,
          );

        this.actionBarErrors = mappedErrors.actionBar.map(e => e.message);
        const toastErrorMessages = mappedErrors.default
          // noServiceTicket errors are handled in a custom modal
          .filter(e => e.key !== 'noServiceTicket')
          .map(e => e.message);

        if (toastErrorMessages.length) {
          this.toastService.add({
            severity: 'warn',
            summary: `Can't complete this procedure`,
            detail: toastErrorMessages.join('\n'),
          });
        }
      });
  }
}

// trims the input before applying semantics similar to Validators.required
export function requiredAfterTrim(
  control: AbstractControl,
): ValidationErrors | null {
  if (control.value?.trim()) {
    return null;
  }
  return { required: true };
}
