// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';

import { getPharmacyDisplay } from '../../../../modules/shared-rx/utils/pharmacy-utils';
import { ChangeRxTypes } from '../../shared/change-rx.type';
import { ChangeRxActions } from '../../store/change-rx.actions';
import { ChangeRxSubmitAction } from '../../store/change-rx.reducer';

@Component({
  selector: 'omg-completed-change-rx',
  templateUrl: './completed-change-rx.component.html',
  styleUrls: ['./completed-change-rx.component.scss'],
})
export class CompletedChangeRxComponent implements OnInit {
  @Input()
  changeRxSubmitAction: ChangeRxSubmitAction;

  changeRxTypes = ChangeRxTypes;

  constructor(private changeRxActions: ChangeRxActions) {}

  get pharmacyPhrase() {
    return getPharmacyDisplay(
      this.changeRxSubmitAction.changeRx.originalMedicationPrescription
        .pharmacy,
    );
  }

  get medForDisplay() {
    const changeRx = this.changeRxSubmitAction?.changeRx;

    if (changeRx) {
      const isNewMedication = changeRx.rxChangeRequest;
      return isNewMedication
        ? changeRx.rxChangeRequest.medForDisplay
        : changeRx.originalMedicationPrescription.medForDisplay;
    }
    return undefined;
  }

  ngOnInit() {
    this.changeRxActions.clearChangeRxSuccess();
  }
}
