// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { patientRoute, PatientSelectors } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { SummaryAssociationType } from '@app/features/summaries/shared/summaries-api.type';
import { pluralizedSnakeCase } from '@app/utils';

import {
  mapTodoEntityToUpdateData,
  mapTodoResponseToEntity,
} from './todo-api-mappers';
import { TodoResponsePayload } from './todo-api.type';
import { Todo } from './todo.type';

/* istanbul ignore next */
/* Appends parameters to the route. */
const parseRouteParams = (params: { [key: string]: any }) => {
  return Object.keys(params).reduce((result, key, index, keys) => {
    let route = `${result}${key}=${params[key]}`;
    /* Append '&' if this parameter is not the last. */
    if (index < keys.length - 1) {
      route += '&';
    }
    return route;
  }, '?');
};

export const todoRoute = (
  id: number,
  subRoute?: string,
  suffixes?: string[],
  params?: { [key: string]: any },
) => {
  let resultPath = '/v2/admin';

  if (subRoute) {
    resultPath = `${resultPath}/${subRoute}`;
  }
  if (id) {
    resultPath = `${resultPath}/${id}`;
  }
  if (suffixes) {
    /* Prepend '/' on every suffix. */
    resultPath = suffixes.reduce(
      (result, suffix) => `${result}/${suffix}`,
      resultPath,
    );
  }
  if (params) {
    resultPath = `${resultPath}` + parseRouteParams(params);
  }

  return resultPath;
};

@Injectable()
export class TodoApiService {
  constructor(
    private api: ApiService,
    private patientSelectors: PatientSelectors,
  ) {}

  getSummaryTodo(summaryId: number) {
    return this.api
      .get<TodoResponsePayload>(todoRoute(summaryId, 'summaries', ['todo']))
      .pipe(
        map((res: TodoResponsePayload) =>
          mapTodoResponseToEntity(res.todo, summaryId, SummaryAssociationType),
        ),
      );
  }

  getNoteTodo(noteId: number) {
    return this.api
      .get<TodoResponsePayload>(todoRoute(noteId, 'notes', ['todo']))
      .pipe(
        map((res: TodoResponsePayload) =>
          mapTodoResponseToEntity(res.todo, noteId, SummaryAssociationType),
        ),
      );
  }

  getPatientTimelinePostTodo(postId: number): Observable<Todo> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId => {
        const endpoint = `${patientRoute(
          patientId,
          '/patient_timeline/posts',
        )}/${postId}/todo`;
        return this.api
          .get<TodoResponsePayload>(endpoint)
          .pipe(
            map(apiResponse =>
              mapTodoResponseToEntity(
                apiResponse.todo,
                apiResponse.todo?.associated_with_id,
                apiResponse.todo?.associated_with_type,
              ),
            ),
          );
      }),
    );
  }

  getEntityTodo(payload: any): Observable<Todo> {
    const { id, type } = payload;
    return this.api
      .get<TodoResponsePayload>(
        todoRoute(id, pluralizedSnakeCase(type), ['todo']),
      )
      .pipe(
        map((res: TodoResponsePayload) => {
          return mapTodoResponseToEntity(res.todo, id, type);
        }),
      );
  }

  update(todo: Todo): Observable<Todo> {
    return this.api
      .update<TodoResponsePayload>(
        todoRoute(todo.id, 'todos'),
        mapTodoEntityToUpdateData(todo),
      )
      .pipe(
        map((res: TodoResponsePayload) =>
          mapTodoResponseToEntity(
            res.todo,
            todo.associatedWithId,
            todo.associatedWithType,
          ),
        ),
      );
  }

  complete(todo: Todo): Observable<Todo> {
    return this.api
      .update<TodoResponsePayload>(
        `${todoRoute(todo.id, 'todos')}/resolve`,
        mapTodoEntityToUpdateData(todo),
      )
      .pipe(
        map((res: TodoResponsePayload) =>
          mapTodoResponseToEntity(
            res.todo,
            todo.associatedWithId,
            todo.associatedWithType,
          ),
        ),
      );
  }

  reopenTodo(todo: Todo): Observable<Todo> {
    return this.api
      .update<TodoResponsePayload>(
        `${todoRoute(todo.id, 'todos')}/reopen`,
        mapTodoEntityToUpdateData(todo),
      )
      .pipe(
        map((res: TodoResponsePayload) =>
          mapTodoResponseToEntity(
            res.todo,
            todo.associatedWithId,
            todo.associatedWithType,
          ),
        ),
      );
  }
}
