import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { ProcedureSuggestionActions } from '@app/features/procedure-suggestion/store/procedure-suggestion.actions';
import { get } from '@app/utils';

import { ProcedureSuggestion } from '../../shared/procedure-suggestion.type';
import { buildMlProcedureBillingSuggestionEventPayload } from '../../shared/procedure-suggestion.utils';

type State = 'suggesting' | 'dismissing' | 'dismissed' | 'ordered' | 'feedback';

@Component({
  selector: 'omg-procedure-suggestion',
  templateUrl: './procedure-suggestion.component.html',
  styleUrls: ['./procedure-suggestion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcedureSuggestionComponent implements OnInit {
  @Input() procedureSuggestion: ProcedureSuggestion;
  @Input() patientId: number;

  feedbackCategories: String[];

  get state(): State {
    if (this.procedureSuggestion.dismissing) {
      return 'dismissing';
    } else if (this.procedureSuggestion.feedback) {
      return 'feedback';
    } else if (this.procedureSuggestion.isDismissed) {
      return 'dismissed';
    } else if (this.procedureSuggestion.itemId) {
      return 'ordered';
    }

    return 'suggesting';
  }

  get name() {
    return get(
      'procedureType[0].clinicalDescription',
      this.procedureSuggestion,
    );
  }

  constructor(
    public procedureSuggestionActions: ProcedureSuggestionActions,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    if (this.state === 'suggesting') {
      this.trackAnalytics(AnalyticsEvent.MlProcedureBillingSuggestionActivated);
    }

    this.feedbackCategories = [
      'Not indicated',
      'Declined',
      'Performed outside OM',
      'Previously completed at OM',
      'Other',
    ];
  }

  createProcedureInteraction() {
    this.procedureSuggestionActions.createProcedureInteraction(
      this.patientId,
      this.procedureSuggestion.problemHistory.problemId,
      this.procedureSuggestion,
    );
    this.trackAnalytics(AnalyticsEvent.MlProcedureBillingSuggestionRecorded);
  }

  dismiss() {
    this.procedureSuggestionActions.dismiss(this.procedureSuggestion.id);
    this.trackAnalytics(AnalyticsEvent.MlProcedureBillingSuggestionDismissed);
  }

  giveFeedback() {
    this.procedureSuggestionActions.giveFeedback(this.procedureSuggestion.id);
  }

  closeFeedback() {
    this.procedureSuggestionActions.closeFeedback(this.procedureSuggestion.id);
  }

  private trackAnalytics(eventType: AnalyticsEvent) {
    this.analytics.track(
      eventType,
      buildMlProcedureBillingSuggestionEventPayload(this.procedureSuggestion),
    );
  }
}
