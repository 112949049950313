import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { PackageOptions } from '../../../../features/renewals/shared/renewals.type';

@Component({
  selector: 'omg-renewal-item-qty',
  templateUrl: './renewal-item-qty.component.html',
})
export class RenewalItemQtyComponent implements OnInit {
  @Input() packageOptions: PackageOptions;
  @Input() quantity: UntypedFormControl;
  @Input() medicationPackageSizeId: UntypedFormControl;
  @Input() set focusOnMedicationKey(id: string) {
    this._focusOnMedicationKey = `renewalCheckoutFocus-${id}`;
  }
  private _focusOnMedicationKey: string;
  packageDescription: string;

  /* istanbul ignore next */
  get focusOnMedicationKey(): string {
    return this._focusOnMedicationKey;
  }

  constructor() {}

  ngOnInit() {
    this.setPackageDescription();
  }

  setPackageDescription() {
    if (!!this.packageOptions.defaultOption.matchedOption) {
      this.packageDescription =
        this.quantity.value === 1
          ? this.packageOptions.defaultOption.matchedOption.desc
          : this.packageOptions.defaultOption.matchedOption.descPlural;
    } else {
      this.quantity.clearValidators();
      this.quantity.updateValueAndValidity();
    }
  }
}
