import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  findAPharmacyOption,
  PatientPharmacy,
  PharmacyOption,
  sendToPrinterOption,
} from '../shared/patient-pharmacy.type';
import {
  PatientPharmacyAction,
  PatientPharmacyTypes,
} from './patient-pharmacy.actions';

export interface PatientPharmacyState extends EntityState<PatientPharmacy> {
  pending: boolean;
  error: any;
  staticPharmacyOptions: PharmacyOption[];
}

function selectPatientPharmacyId(patientPharmacy: PatientPharmacy): number {
  return patientPharmacy.id;
}

export const adapter: EntityAdapter<PatientPharmacy> =
  createEntityAdapter<PatientPharmacy>({
    selectId: selectPatientPharmacyId,
    sortComparer: false,
  });

export const initialState: PatientPharmacyState = adapter.getInitialState({
  pending: false,
  error: null,
  staticPharmacyOptions: [findAPharmacyOption, sendToPrinterOption],
});

export function reducer(
  state = initialState,
  action: PatientPharmacyAction,
): PatientPharmacyState {
  switch (action.type) {
    case PatientPharmacyTypes.LOAD_PATIENT_PHARMACIES: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case PatientPharmacyTypes.LOAD_PATIENT_PHARMACIES_SUCCESS: {
      return adapter.addMany(action.payload, {
        ...state,
        pending: false,
        error: null,
      });
    }

    case PatientPharmacyTypes.LOAD_PATIENT_PHARMACIES_ERROR: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    case PatientPharmacyTypes.CREATE_PATIENT_PHARMACY: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case PatientPharmacyTypes.CREATE_PATIENT_PHARMACY_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        pending: false,
        error: null,
      });
    }

    case PatientPharmacyTypes.CREATE_PATIENT_PHARMACY_ERROR: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    case PatientPharmacyTypes.DELETE_PATIENT_PHARMACY: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case PatientPharmacyTypes.DELETE_PATIENT_PHARMACY_SUCCESS: {
      return adapter.removeOne(action.payload, {
        ...state,
        pending: false,
        error: null,
      });
    }

    case PatientPharmacyTypes.DELETE_PATIENT_PHARMACY_ERROR: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    case PatientPharmacyTypes.RESET_PREFERRED_PATIENT_PHARMACIES: {
      const entitiesToUpdate = action.payload.map(entity => ({
        id: entity.id,
        changes: {
          ...entity,
          isPreferred: false,
        },
      }));
      return adapter.updateMany(entitiesToUpdate, { ...state });
    }

    default:
      return state;
  }
}
