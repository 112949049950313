import {
  format,
  formatDistanceStrict as formatDistance,
  formatDuration,
  intervalToDuration,
  isAfter,
  isValid,
  parseISO,
  toDate,
} from 'date-fns';

/**
 * Re-export functions from date-fns
 */

export {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  addYears,
  compareAsc as compareDatesAsc,
  compareDesc as compareDatesDesc,
  differenceInMinutes,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  endOfDay,
  endOfMonth,
  format,
  isAfter,
  isBefore as isDateBefore,
  isSameDay,
  isValid,
  parse as parseDate,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subSeconds,
  toDate,
} from 'date-fns';

export const dateIsString = (date: string | number | Date | null): boolean =>
  typeof date === 'string';

export const formatDistanceStrict = (
  date: Date | string | number,
  baseDate: number | Date,
  options?: {
    addSuffix: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
    locale?: Locale;
  },
): string | number | Date => {
  const computedDate = dateIsString(date)
    ? parseISO(`${date}`)
    : toDate(<Date | number>date);
  return isValid(computedDate)
    ? formatDistance(computedDate, baseDate, options)
    : date;
};

export const isDateAfter = (
  date: Date | number | string,
  dateToCompare: Date | number | string | null,
): boolean => {
  const firstDate = dateIsString(date)
    ? parseISO(`${date}`)
    : <Date | number>date;
  const comparisonDate = dateIsString(dateToCompare)
    ? parseISO(`${dateToCompare}`)
    : <Date | number>dateToCompare;

  return isAfter(firstDate, comparisonDate);
};

export const isPastDue = (dueAt: Date | string | null): boolean =>
  isDateAfter(new Date(), dueAt);

export const formatDateToDistance = (date: Date): string =>
  formatDistance(date, Date.now());

export const formatDistanceBetweenDates = (
  date1: Date,
  date2: Date,
): string => {
  const interval = intervalToDuration({ start: date2, end: date1 });
  return formatDuration(interval, {
    format: ['years', 'months', 'days'],
    delimiter: ', ',
  });
};

export const formatDate = (
  date: Date | string | number,
  dateFormat: string,
  options?: {
    locale: Locale;
    weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate: number;
    useAdditionalWeekYearTokens: boolean;
    useAdditionalDayOfYearTokens: boolean;
  },
): string | null => {
  const computedDate = dateIsString(date)
    ? parseISO(`${date}`)
    : <number | Date>date;
  return isValid(computedDate)
    ? format(computedDate, dateFormat, options)
    : null;
};

/**
 * Accepts a short iso8601 date string like 'YYYY-MM-DD' and returns a Date
 * in the local timezone.
 */
export function utcDateToLocalDate(source: string): Date {
  const date = new Date(source);
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}
