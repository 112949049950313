// @ts-strict-ignore
import { Directive, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: 'omg-collapse, [omgCollapse]',
  exportAs: 'omgCollapse',
})
export class CollapseDirective implements OnInit {
  private expandedChangeSubject = new BehaviorSubject(false);
  private expandedAnalyticsChangeSubject = new BehaviorSubject(null);

  @Input() expanded = false;
  @Input() disabled: boolean;
  @Output() expandedChange = this.expandedChangeSubject.asObservable();
  @Output()
  analyticsExpandedChange = this.expandedAnalyticsChangeSubject.asObservable();
  // NOTE: These are to help preserve existing styling
  // TODO: #171991618 migrate animation styles here once migration is complete
  @HostBinding('class.om-collapse') collapseClass = true;
  @HostBinding('attr.om-collapse') collapseAttribute = true;

  @HostBinding('class.om-collapse-expanded')
  get expandedClass() {
    return this.expanded;
  }

  @HostBinding('class.-disabled')
  get disabledClass() {
    return this.disabled;
  }

  constructor() {}

  ngOnInit() {
    if (this.expandedChangeSubject.value !== this.expanded) {
      this.expandedChangeSubject.next(this.expanded);
    }
  }

  expand() {
    this.setExpandedState(true);
  }

  collapse() {
    this.setExpandedState(false);
  }

  toggle() {
    if (!this.disabled) {
      this.setExpandedState(!this.expanded);
    }
  }

  setExpandedState(value: boolean) {
    if (this.expanded !== value) {
      this.expanded = value;
      this.expandedChangeSubject.next(value);
      this.expandedAnalyticsChangeSubject.next(value);
    }
  }
}
