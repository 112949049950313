// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { MutationResult } from 'apollo-angular';
import { HelpFlow } from '../../../graphql/onelife.type';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { DropdownItem } from '@app/shared';

import { ToastMessageService } from '../../shared/components/toast';
import { ReferenceDataKeys, ReferenceDataSelectors } from '../reference-data';
import {
  CreateHelpRequestMutation,
  CreateHelpRequestMutationService,
} from './graphql/mutations.onelife.generated';
import {
  HelpFlowTypesQuery,
  HelpFlowTypesQueryService,
} from './graphql/queries.onelife.generated';
import { LicensingBodiesResponse, USState } from './help-request.type';

export interface HelpRequestOptions {
  patientId: number;
  linkedNoteId?: number;
  summary?: Partial<Summary>;
  actionUrl?: string;
}
export interface HelpRequestDetails {
  options: HelpRequestOptions;
  helpflowType: string;
  details: string;
  licensingBodyId: string;
  callerOnTheLine: boolean;
  callbackName?: string;
  callbackNumber?: string;
  urgency?: string;
  facility?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HelpRequestService {
  constructor(
    private toastService: ToastMessageService,
    private patientSelectors: PatientSelectors,
    private referenceDataSelectors: ReferenceDataSelectors,
    private helpFlowTypesQuery: HelpFlowTypesQueryService,
    private createHelpRequestMutation: CreateHelpRequestMutationService,
  ) {}

  getHelpFlowTypes() {
    return this.helpFlowTypesQuery.fetch().pipe(
      map(
        (response: ApolloQueryResult<HelpFlowTypesQuery>) =>
          response.data.helpflowTypes,
      ),
      map((data: HelpFlow[]): DropdownItem[] =>
        this.mapHelpFlowTypesToDropdown(data),
      ),
    );
  }

  getLicensingBodies(): Observable<DropdownItem[]> {
    return this.referenceDataSelectors
      .get(ReferenceDataKeys.licensingBodies)
      .pipe(
        map((licensingBodies: LicensingBodiesResponse['licensingBodies']) =>
          this.mapStatesToDropdown(licensingBodies),
        ),
      );
  }

  getLicensingBodyAndStates() {
    return combineLatest([
      this.patientSelectors.patient,
      this.getLicensingBodies(),
    ]).pipe(
      switchMap(([patient, states]: [Patient, DropdownItem[]]) =>
        of({ licensingBodyId: patient.licensingBody.id, states }),
      ),
    );
  }

  getHelp(requestDetails: HelpRequestDetails) {
    const {
      details,
      options,
      helpflowType,
      licensingBodyId,
      callerOnTheLine,
      callbackName,
      callbackNumber,
      urgency,
      facility,
    } = requestDetails;
    const { actionUrl, linkedNoteId, patientId } = options;
    const variables = {
      helpflowType,
      attributes: {
        details,
        actionUrl,
        linkedNoteId,
        patientId: patientId.toString(),
        licensingBodyId: licensingBodyId.toString(),
        callerOnTheLine,
        callbackName,
        callbackNumber,
        urgency,
        facility,
      },
    };

    return this.createHelpRequestMutation.mutate(variables).pipe(
      tap((helpRequestResponse: MutationResult<CreateHelpRequestMutation>) => {
        this.successToast(
          helpRequestResponse.data.createHelpRequest.helpRequest.slackChannel,
        );
      }),
      catchError(error => this.errorToast(error)),
    );
  }

  private mapHelpFlowTypesToDropdown(
    helpflowTypes: HelpFlow[],
  ): DropdownItem[] {
    return helpflowTypes.map((type: HelpFlow) => ({
      label: type.displayName,
      value: type.id,
    }));
  }

  private mapStatesToDropdown(usStates: USState[]): DropdownItem[] {
    return usStates.map(state => ({
      label: `${state.name} (${state.shortName})`,
      value: state.id,
    }));
  }

  private successToast(channelName: string) {
    this.toastService.add({
      severity: 'success',
      summary: 'Your request has been assigned',
      detail: `View your request in the <b>${channelName}</b> channel.`,
    });
  }

  private errorToast(error: any) {
    this.toastService.add({
      severity: 'error',
      summary: 'Your request is incomplete',
      detail: `${
        error.status === 500 ? `A server error occurred. ` : ''
      }Please try again`,
    });
    return error;
  }
}
