import { Injectable } from '@angular/core';
import { addHours } from 'date-fns';
import { CookieOptions, CookieService as NgxCookieService } from 'ngx-cookie';

const TIMELINE_NOTE_PRIVACY_BROWSER_STORAGE_KEY_PREFIX =
  'timeline_note_privacy:';

const TIMELINE_PRIVACY_CONSENT_MAX_EXPIRATION_HOURS = 8;

/**
 * The PrivacyStorageService is an abstraction of the functionality
 * required for the "break-the-glass" feature. Version 1, will obfuscate from the user on screen
 * even while the data is available in the browser.  Future versions should expand on the methods
 * below for a more robust solution
 */
@Injectable({
  providedIn: 'root',
})
export class PrivacyConsentStorageService {
  constructor(private cookieService: NgxCookieService) {}

  setConsentCookie(patientId) {
    const cookieOptions: CookieOptions = {
      expires: this.getNoteConsentExpirationTime(),
    };
    this.cookieService.put(
      `${TIMELINE_NOTE_PRIVACY_BROWSER_STORAGE_KEY_PREFIX}${patientId}`,
      '1',
      cookieOptions,
    );
  }

  clearConsentCookies() {
    const allCookies = this.cookieService.getAll();
    for (const cookieKey in allCookies) {
      if (
        cookieKey.indexOf(TIMELINE_NOTE_PRIVACY_BROWSER_STORAGE_KEY_PREFIX) >= 0
      ) {
        this.cookieService.remove(cookieKey);
      }
    }
  }

  isConsentSaved(patientId: number) {
    const cookieVal = this.cookieService.get(
      `${TIMELINE_NOTE_PRIVACY_BROWSER_STORAGE_KEY_PREFIX}${patientId}`,
    );
    if (cookieVal && cookieVal.length) {
      JSON.parse(cookieVal);
    }
    return cookieVal && cookieVal.length > 0;
  }

  private getNoteConsentExpirationTime(): Date {
    return addHours(Date.now(), TIMELINE_PRIVACY_CONSENT_MAX_EXPIRATION_HOURS);
  }
}
