import { Injectable } from '@angular/core';
import { setup } from '@onemedical/command-palette';

import { ConfigService } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class CommandPaletteService {
  constructor(private configService: ConfigService) {}

  init() {
    this.setup();
  }

  public isElementCommandPaletteElement(element: Element) {
    return element.tagName === 'NINJA-KEYS';
  }

  setup = () => {
    setup({
      params: {
        adminUrl: this.configService.environment.adminApp.host,
        onelifeUrl: this.configService.environment.legacyUi.host,
        templateUrl: this.configService.environment.templatesApp.host,
      },
    });
  };
}
