// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UpdateNum } from '@ngrx/entity/src/models';
import {
  Action,
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { ErrorHandlerService, FsaAction } from '@app/core';
import {
  EntityChangesPayload,
  EntityIdentifierPayload,
  IEntityApiService,
} from '@app/core/store/shared/entity-api-type';

export interface StoreEntityState<T> extends EntityState<T> {
  loading: boolean;
  error: any;
}

export interface SavedEntity {
  id: number;
}

export class StateSelector<T> {
  protected adapter: EntityAdapter<T> = createEntityAdapter<T>();

  protected selectState = createFeatureSelector<StoreEntityState<T>>(
    this.stateConfig.statePath,
  );
  protected selectIds = createSelector(
    this.selectState,
    this.adapter.getSelectors().selectIds,
  );
  protected selectEntities = createSelector(
    this.selectState,
    this.adapter.getSelectors().selectAll,
  );
  protected selectEntitiesDictionary = createSelector(
    this.selectState,
    this.adapter.getSelectors().selectEntities,
  );
  protected selectEntity = createSelector(
    this.selectEntitiesDictionary,
    (entities, { id }) => entities[id],
  );
  protected selectLoading = createSelector(
    this.selectState,
    state => state.loading,
  );
  protected selectError = createSelector(
    this.selectState,
    state => state.error,
  );

  constructor(
    protected store: Store<StoreEntityState<T>>,
    protected stateConfig: StateConfig,
  ) {}

  get ids(): Observable<number[] | string[]> {
    return this.store.pipe(select(this.selectIds));
  }

  get entities(): Observable<T[]> {
    return this.store.pipe(select(this.selectEntities));
  }

  get loading(): Observable<boolean> {
    return this.store.pipe(select(this.selectLoading));
  }

  get error(): Observable<any> {
    return this.store.pipe(select(this.selectError));
  }

  getById(id: number): Observable<T> {
    return this.store.pipe(select(this.selectEntity, { id }));
  }

  filter(predicate: (entity: T) => boolean): Observable<T[]> {
    return this.entities.pipe(
      map((entities: T[]) => entities.filter(predicate)),
    );
  }
}
export interface StateConfig {
  statePath: string;
  entityName: string;
  pluralName: string;
}

export enum ActionNames {
  Create = 'Create',
  CreateSuccess = 'Create Success',
  CreateError = 'Create Error',
  Load = 'Load',
  LoadSuccess = 'Load Success',
  LoadError = 'Load Error',
  GetById = 'Get By Id',
  GetByIdSuccess = 'Get By Id Success',
  GetByIdError = 'Get By Id Error',
  Update = 'Update',
  UpdateSuccess = 'Update Success',
  UpdateError = 'Update Error',
  Delete = 'Delete',
  DeleteSuccess = 'Delete Success',
  DeleteError = 'Delete Error',
}

const isPluralAction = (actionName: ActionNames) => {
  return (
    actionName &&
    [ActionNames.Load, ActionNames.LoadSuccess, ActionNames.LoadError].includes(
      actionName,
    )
  );
};

export type ActionNameTypeMap = { [actionName in ActionNames]?: string };

const getAction = (
  entityName: string,
  actionName: ActionNames,
  entityText?: string,
) => {
  return `[${entityName}] ${actionName} - ${entityText || entityName}`;
};

export const ActionNameTypeMapFactory = (
  stateConfig: StateConfig,
): ActionNameTypeMap => {
  const actionNameTypeMap = Object.values(ActionNames).reduce(
    (acc, actionNameValue) => {
      acc[actionNameValue] = getAction(
        stateConfig.entityName,
        actionNameValue,
        isPluralAction(actionNameValue) ? stateConfig.pluralName : undefined,
      );
      return acc;
    },
    {},
  );

  return <ActionNameTypeMap>actionNameTypeMap;
};

export const buildTokenName = (stateConfig: StateConfig) =>
  `${stateConfig.entityName} Reducer`;

export class ErrorAction<T> implements FsaAction<T> {
  type = undefined;
  error = true;

  constructor(
    public payload: any,
    protected actionNameTypeMap: ActionNameTypeMap,
    public entity?: any,
  ) {}
}

export class Create<T> implements FsaAction<Partial<T>> {
  readonly type = this.actionNameTypeMap[ActionNames.Create];

  constructor(
    public payload: Partial<T>,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class CreateSuccess<T> implements FsaAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.CreateSuccess];

  constructor(
    public payload: T,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class CreateError<T> extends ErrorAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.CreateError];
}

export class GetById<T> implements FsaAction<EntityIdentifierPayload<T>> {
  readonly type = this.actionNameTypeMap[ActionNames.GetById];

  constructor(
    public payload: EntityIdentifierPayload<T>,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class GetByIdSuccess<T> implements FsaAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.GetByIdSuccess];

  constructor(
    public payload: T,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

class GetByIdError<T> extends ErrorAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.GetByIdError];
}

export class Load<T> implements FsaAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.Load];

  constructor(private actionNameTypeMap: ActionNameTypeMap) {}
}

export class LoadSuccess<T> implements FsaAction<T[]> {
  readonly type = this.actionNameTypeMap[ActionNames.LoadSuccess];

  constructor(
    public payload: T[],
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class LoadError<T> extends ErrorAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.LoadError];
}

export class Update<T> implements FsaAction<EntityChangesPayload<T>> {
  readonly type = this.actionNameTypeMap[ActionNames.Update];

  constructor(
    public payload: EntityChangesPayload<T>,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class UpdateSuccess<T> implements FsaAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.UpdateSuccess];

  constructor(
    public payload: T,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class UpdateError<T> extends ErrorAction<T> {
  readonly type = this.actionNameTypeMap[ActionNames.UpdateError];
}

export class Delete<T> implements FsaAction<EntityIdentifierPayload<T>> {
  readonly type = this.actionNameTypeMap[ActionNames.Delete];

  constructor(
    public payload: EntityIdentifierPayload<T>,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class DeleteSuccess implements FsaAction<number> {
  readonly type = this.actionNameTypeMap[ActionNames.DeleteSuccess];

  constructor(
    public payload: number,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}
}

export class DeleteError extends ErrorAction<number> {
  readonly type = this.actionNameTypeMap[ActionNames.DeleteError];
}

export class StateActions<T> {
  constructor(
    protected store: Store<StoreEntityState<T>>,
    private actionNameTypeMap: ActionNameTypeMap,
  ) {}

  create(entity: Partial<T>) {
    this.store.dispatch(new Create<T>(entity, this.actionNameTypeMap));
  }

  load() {
    this.store.dispatch(new Load<T>(this.actionNameTypeMap));
  }

  update(payload: EntityChangesPayload<T>) {
    this.store.dispatch(new Update(payload, this.actionNameTypeMap));
  }

  getById(payload: EntityIdentifierPayload<T>) {
    this.store.dispatch(new GetById(payload, this.actionNameTypeMap));
  }

  delete(payload: EntityIdentifierPayload<T>) {
    this.store.dispatch(new Delete(payload, this.actionNameTypeMap));
  }
}

// union type for additional action types
export type StateAction<T> =
  | Create<T>
  | CreateSuccess<T>
  | CreateError<T>
  | GetById<T>
  | GetByIdSuccess<T>
  | GetByIdError<T>
  | Update<T>
  | UpdateSuccess<T>
  | UpdateError<T>
  | Load<T>
  | LoadSuccess<T>
  | LoadError<T>
  | Delete<T>
  | DeleteSuccess
  | DeleteError;

export class StateEffects<T> {
  constructor(
    protected actions$: Actions,
    protected entityApiService: IEntityApiService<T>,
    protected actionNameTypeMap: ActionNameTypeMap,
    protected errorHandlerService: ErrorHandlerService,
  ) {}

  createEntity$: Observable<Action> = this.actions$.pipe(
    ofType<Create<T>>(this.actionNameTypeMap[ActionNames.Create]),
    switchMap(action =>
      this.entityApiService.save(action.payload).pipe(
        map(response => new CreateSuccess<T>(response, this.actionNameTypeMap)),
        catchError((error: HttpErrorResponse) =>
          of(
            new CreateError<T>(
              this.errorHandlerService.handleError(error),
              this.actionNameTypeMap,
              action.payload,
            ),
          ),
        ),
      ),
    ),
    shareReplay(),
  );

  createEntitySuccess$ = this.actions$.pipe(
    ofType<CreateSuccess<T>>(this.actionNameTypeMap[ActionNames.CreateSuccess]),
  );

  loadEntities$ = this.actions$.pipe(
    ofType<Load<T>>(this.actionNameTypeMap[ActionNames.Load]),
    switchMap(action =>
      this.entityApiService.getAll().pipe(
        map(response => new LoadSuccess<T>(response, this.actionNameTypeMap)),
        catchError((error: HttpErrorResponse) =>
          of(
            new LoadError<T>(
              this.errorHandlerService.handleError(error),
              this.actionNameTypeMap,
            ),
          ),
        ),
      ),
    ),
    shareReplay(),
  );

  getEntityById$ = this.actions$.pipe(
    ofType<GetById<T>>(this.actionNameTypeMap[ActionNames.GetById]),
    switchMap(action =>
      this.entityApiService.getById(action.payload).pipe(
        map(
          response => new GetByIdSuccess<T>(response, this.actionNameTypeMap),
        ),
        catchError((error: HttpErrorResponse) =>
          of(
            new GetByIdError<T>(
              this.errorHandlerService.handleError(error),
              this.actionNameTypeMap,
              action.payload,
            ),
          ),
        ),
      ),
    ),
    shareReplay(),
  );

  updateEntity$ = this.actions$.pipe(
    ofType<Update<T>>(this.actionNameTypeMap[ActionNames.Update]),
    switchMap(action =>
      this.entityApiService
        .update({
          id: action.payload.id,
          changes: action.payload.changes,
          options: action.payload.options,
        })
        .pipe(
          map(
            response => new UpdateSuccess<T>(response, this.actionNameTypeMap),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              new UpdateError<T>(
                this.errorHandlerService.handleError(error),
                this.actionNameTypeMap,
                action.payload,
              ),
            ),
          ),
        ),
    ),
    shareReplay(),
  );

  updateEntitySuccess$ = this.actions$.pipe(
    ofType<UpdateSuccess<T>>(this.actionNameTypeMap[ActionNames.UpdateSuccess]),
  );

  deleteEntity$ = this.actions$.pipe(
    ofType<Delete<T>>(this.actionNameTypeMap[ActionNames.Delete]),
    switchMap(action =>
      this.entityApiService.delete(action.payload).pipe(
        map(id => new DeleteSuccess(id, this.actionNameTypeMap)),
        catchError((error: HttpErrorResponse) =>
          of(
            new DeleteError(
              this.errorHandlerService.handleError(error),
              this.actionNameTypeMap,
              action.payload,
            ),
          ),
        ),
      ),
    ),
    shareReplay(),
  );

  deleteEntitySuccess$ = this.actions$.pipe(
    ofType<DeleteSuccess>(this.actionNameTypeMap[ActionNames.DeleteSuccess]),
  );

  deleteEntityError$ = this.actions$.pipe(
    ofType<DeleteError>(this.actionNameTypeMap[ActionNames.DeleteError]),
  );
}

type CustomAction<T, A> = A & FsaAction<T | number>;

export function stateReducerFactory<
  T,
  A = {},
  CustomEntityState = StoreEntityState<T>,
>(
  actionNameTypeMap: ActionNameTypeMap,
  entityAdapter: EntityAdapter<T>,
  customReducer?: any,
  initialState: StoreEntityState<T> = entityAdapter.getInitialState({
    loading: false,
    error: null,
  }),
) {
  const loadingState = { error: null, loading: true };
  const loadingSuccessState = { error: null, loading: false };
  const loadingErrorState = { loading: false };
  const updateErrorState = { loading: false };

  return (
    state = initialState,
    action: StateAction<T> | CustomAction<T, A>,
  ): CustomEntityState | StoreEntityState<T> => {
    switch (action.type) {
      case actionNameTypeMap[ActionNames.Load]:
        return { ...state, ...loadingState };

      case actionNameTypeMap[ActionNames.LoadSuccess]:
        return entityAdapter.setAll((<LoadSuccess<T>>action).payload, {
          ...state,
          ...loadingSuccessState,
        });

      case actionNameTypeMap[ActionNames.LoadError]:
        return {
          ...state,
          ...loadingErrorState,
          error: (<LoadError<T>>action).payload,
        };

      case actionNameTypeMap[ActionNames.Create]:
        return { ...state, ...loadingState };

      case actionNameTypeMap[ActionNames.CreateSuccess]:
        return entityAdapter.addOne((<CreateSuccess<T>>action).payload, {
          ...state,
          ...loadingSuccessState,
        });

      case actionNameTypeMap[ActionNames.CreateError]:
        return {
          ...state,
          ...loadingErrorState,
          error: (<CreateError<T>>action).payload,
        };

      case actionNameTypeMap[ActionNames.GetById]:
        return { ...state, ...loadingState };

      case actionNameTypeMap[ActionNames.GetByIdSuccess]:
        return entityAdapter.upsertOne((<GetByIdSuccess<T>>action).payload, {
          ...state,
          loading: false,
          error: null,
        });

      case actionNameTypeMap[ActionNames.GetByIdError]:
        return {
          ...state,
          ...loadingErrorState,
          error: (<GetByIdError<T>>action).payload,
        };

      case actionNameTypeMap[ActionNames.DeleteSuccess]:
        return entityAdapter.removeOne((<DeleteSuccess>action).payload, {
          ...state,
          ...loadingSuccessState,
        });

      case actionNameTypeMap[ActionNames.Update]:
        return { ...state, ...loadingState };

      case actionNameTypeMap[ActionNames.UpdateSuccess]:
        const updatedEntity = (<UpdateSuccess<T>>action).payload as T &
          SavedEntity;
        const update: UpdateNum<T> = {
          id: updatedEntity.id, // the orders store only reduces status changes
          changes: updatedEntity,
        };

        return entityAdapter.updateOne(update, {
          ...state,
          ...loadingSuccessState,
        });

      case actionNameTypeMap[ActionNames.UpdateError]:
        return {
          ...state,
          ...updateErrorState,
          error: (<UpdateError<T>>action).payload,
        };

      default:
        return customReducer ? customReducer(state, action) : state;
    }
  };
}
