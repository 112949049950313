import { Order } from './order.type';
import { OrderWithConsultantTypes as OrderTypes } from '../../../../graphql/onelife.type';
const generateOrderRoute = (order: Order) =>
  orderEditRouteMap[order.type].replace(':id', order.id.toString());

export const orderViewRouteMap = {
  FollowUpOrder: 'follow_up_orders/:orderType/:id/view',
  VisitFollowUpOrder: `follow_up_orders/visit/:id/view`,
  BasicFollowUpOrder: `follow_up_orders/basic/:id/view`,
  GenericFollowUpOrder: `follow_up_orders/generic/:id/view`,
  SurveyOrder: 'survey_orders/:id/view',
  ConnectedDeviceOrder: 'connected_device_orders/:id/view',
};

export const editConsultOrderRoutePartial = 'edit_consult_order';
export const procedureOrderRoutePartial = 'procedure_orders';
export const procedureInteractionRoute = 'procedures';

export const orderEditRouteMap = {
  FollowUpOrder: 'follow_up_orders/:orderType/:id/edit',
  VisitFollowUpOrder: `follow_up_orders/visit/:id/edit`,
  BasicFollowUpOrder: `follow_up_orders/basic/:id/edit`,
  GenericFollowUpOrder: `follow_up_orders/generic/:id/edit`,
  ConsultOrder: `:id/${editConsultOrderRoutePartial}`,
  LabOrder: ':id/edit',
  ProcedureOrder: `${procedureOrderRoutePartial}/:id/edit`,
  VaccineOrder: 'vaccine_orders/:id/edit',
};

export const orderCreateRouteMap = {
  BasicFollowUpOrder: 'follow_up_orders/new',
  ConsultOrder: '',
  GenericFollowUpOrder: 'follow_up_orders/new',
  LabOrder: 'lab_orders',
  ProcedureOrder: procedureOrderRoutePartial,
  SurveyOrder: 'survey_orders/new',
  VaccineOrder: 'vaccine_orders',
  VisitFollowUpOrder: 'follow_up_orders/new',
  ConnectedDeviceOrder: 'connected_device_orders/new',
};

export const orderApiMap = {
  BasicFollowUpOrder: '/v2/admin/patients/:patientId/basic_follow_up_orders',
  ConsultOrder: '/v2/admin/consult_orders',
  GenericFollowUpOrder:
    '/v2/admin/patients/:patientId/generic_follow_up_orders',
  LabOrder: '/v2/admin/lab_orders',
  ProcedureOrder: `/v2/admin/${procedureOrderRoutePartial}`,
  SurveyOrder: `/v2/admin/patients/:patientId/survey_orders`,
  VisitFollowUpOrder: '/v2/admin/patients/:patientId/visit_follow_up_orders',
  ConnectedDeviceOrder: '/v2/admin/patients/:patientId/connected_device_orders',
};

const orderCreateApiMap = {
  BasicFollowUpOrder: '/v2/admin/patients/:patientId/basic_follow_up_orders',
  ConsultOrder: '/v2/admin/patients/:patientId/consult_orders',
  GenericFollowUpOrder:
    '/v2/admin/patients/:patientId/generic_follow_up_orders',
  LabOrder: '/v2/admin/patients/:patientId/lab_orders',
  ProcedureOrder: `/v2/admin/patients/:patientId/${procedureOrderRoutePartial}`,
  SurveyOrder: `/v2/admin/patients/:patientId/survey_orders`,
  VaccineOrder: '/v2/admin/patients/:patientId/vaccine_orders',
  VisitFollowUpOrder: '/v2/admin/patients/:patientId/visit_follow_up_orders',
  ConnectedDeviceOrder: '/v2/admin/patients/:patientId/connected_device_orders',
};

export const getOrderEditPath = (patientId: number, order: Order) =>
  `patients/${patientId}/chart/orders/${generateOrderRoute(order)}`;

export const getOrderApiPath = (orderType: OrderTypes, patientId: number) => {
  const orderPath = orderApiMap[orderType];
  return orderPath.replace(':patientId', patientId.toString());
};

export const getCreateOrderApiPath = (
  orderType: OrderTypes,
  patientId: number,
) => {
  const orderPath = orderCreateApiMap[orderType];
  return orderPath.replace(':patientId', patientId.toString());
};
