import { Component, Input } from '@angular/core';

import { PatientPharmacy } from '@app/modules/pharmacy-picker/shared/patient-pharmacy.type';

@Component({
  selector: 'omg-checkout-pharmacy-info',
  templateUrl: './checkout-pharmacy-info.component.html',
  styleUrls: ['./checkout-pharmacy-info.component.css'],
})
export class CheckoutPharmacyInfoComponent {
  @Input() patientPharmacy: PatientPharmacy;
  @Input() isPrint: boolean;

  constructor() {}
}
