import { RouterReducerState } from '@ngrx/router-store';
import { routerReducer as storeRouterReducer } from '@ngrx/router-store';

import { Params } from '@angular/router';

interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export type RouterState = RouterReducerState<RouterStateUrl>;
export const routerStateKey = 'router';
export const routerReducer = storeRouterReducer;
