import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService as CookiesService } from 'ngx-cookie';

@Injectable()
export class LoginGuard {
  constructor(
    private cookie: CookiesService,
    private router: Router,
  ) {}

  canActivate() {
    return this.cookie.get('auth0.is.authenticated')
      ? this.router.parseUrl(this.getRedirectPath())
      : true;
  }

  getRedirectPath() {
    return window.sessionStorage.getItem('path') || '/schedule';
  }
}
